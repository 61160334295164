import axios from "axios";

const baseUrl = "https://dedonemilelio.org/coupons?tid=1028738&auth=vhjkfehvuehr&osr=fill&puid=false&country=";

export function getAllCoupons(country) {
    return axios({
        method: 'get',
        url: `${baseUrl}${country}`,
    }).then(function (response) {
        return response.data
    });
}
